/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('settings', settingsService);

function settingsService(http) {
    let url = config.apiURL + 'settings/', errors = [422, 500];
    function errorHandler(err) {
        if (err && err.data && err.data.message) {
            return Promise.reject(err.data.message);
        }
        return Promise.reject(err);
    }
    return {
        ip: {
            get: () => {
                return new Promise((resolve, reject) => {
                    http.get(url + 'ip').then((res) => {
                        if (res.data) {
                            resolve(res.data);
                        } else {
                            reject();
                        }
                    }).catch(errorHandler);
                });
            },
            add: (item) => {
                return http.post(url + 'ip', item).then((res) => {
                    return Promise.resolve(res.data);
                }).catch(errorHandler);
            },
            delete: (item) => {
                return http.delete(url + 'ip/' + item).then((res) => {
                    return Promise.resolve(res);
                }).catch(errorHandler);
            }
        }
    };
}
